* {
    box-sizing: border-box;
}
h3,h2{
    font-weight: bold;
}
body {
    font-family:  "Poppins", sans-serif;
    overflow-x: hidden;
}
#ui-to-top {
    display: none;
}
.lazyload-wrapper {
    display: unset;
}
.row.tabs-dynamic {
    background: #fff0f5;
    padding: 20px;
    margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
    margin-bottom: 0;
}
.row.tabs-dynamic p a {
    color: #585757;
}
.row.tabs-dynamic select {
    width: 100%;
    border: 0px;
    background: transparent;
    padding: 8px 5px;
    border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
    padding: 9px 5px;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #b6b6b6;
    background: transparent;
}
.count-val p {
    margin-bottom: 0px;
}
.load-more .next {
    background: #273c5b;
    width: max-content;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
}
.read_more {
    border-radius: 20px;
    padding: 10px 15px;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    position: relative;
    background: #112e16;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #000;
    margin-top: 15px;
    font-size: 13px;
}

img {
    max-width: 100%;
}

.clr {
    clear: both;
}
.clr5 {
    clear: both;
    height: 10px;
}
.clr10 {
    clear: both;
    height: 10px;
}
.clr15 {
    height: 18px;
    clear: both;
}
.clr20 {
    clear: both;
    height: 20px;
}

.clr25 {
    clear: both;
    height: 30px;
}

a:hover, a:focus {
    outline: none !important;
}

input {
    outline: none !important;
}

h2 {
    font-size: 25px;
    color: #000000;
    clear: both;
    margin-bottom: 30px;
    line-height: 45px;
    font-size: 30px;
}


a {
    text-decoration: none;
}

.readmore .fa {
    background: #d41259;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    color: #fff;
}

.readmore {
    background: #ffffff;
    border: solid 1px #c7c7c7;
    text-align: center;
    padding: 3px 15px;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    transition-property: color;
    transition-duration: 0.3s;
    color: #000000;
    font-size: 15px;
    margin: 10px auto 0 auto;
    display: block;
    width: max-content;
}

.readmore:hover {
    color: #fff;
}

.readmore:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #e53777;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.readmore:hover:before, .readmore:focus:before, .readmore:active:before {
 
    transform: scale(1);
}

p {
    font-size: 16px;
    color: #525252;
    line-height: 32px;
    text-align: justify;
}

ul li {
    list-style-type: none;
}

.menu_sec.fix-header {
    /* background: #ffffff; */
    position: fixed;
    width: 100%;
    z-index: 9999;
    -webkit-transition: width 1s;
    transition: width 1s;
    transition: background 0.5s;
    top: 0px;
    padding-bottom: 3px;
}

.menu_sec.fix-header img {
    /* width: 175px; */
    margin-top: 1px;
    /* height: 50px; */
}

.menu_sec.fix-header #cssmenu {
    /* margin-top: 5px; */
    margin-bottom: 0px;
}

.menu_btn {
    background: #fff;
    position: fixed;
    top: 40px;
    right: 20px;
    z-index: 9999999;
    height: 48px;
    width: 136px;
    padding-top: 12px;
    text-align: center;
    padding-left: 20px;
    border-radius: 55px;
    border: 1px solid grey;
    CURSOR: POINTER;
}

.menu_btn .active {
    color: #000;
}

.news_sec {
    margin: 0px;
    height: 560px;
    overflow: hidden;
}

.news_sec  {
    background: #d41259;
    padding-top: 20px;
    padding-bottom: 20px;
    padding: 20px;
}

.news_sec h2 {
    margin-bottom: 20px;
    color: #fff;
}

.news_sec marquee {
    height: 440px;
    margin-top: 23px;
}


.news_sec .container {
    position: relative;
}

.news_sec .news_btn {
    top: -63px;
    position: absolute;
    left: 15px;
}

.enquiry-form {
    width: 150px !important;
    margin: auto;
}

.news_sec .homenews {
    position: relative;
    /* background: rgb(0 0 0 / 32%); */
    padding: 10px;
    height: 114px;
    margin-bottom: 20px;
    border: solid 1px #ffffff4d;
}

.news_sec .homenews .news_tag {
    position: absolute;
    z-index: 9;
    right: 10px;
    width: 15px;
    height: 15px;
    top: 0px;
}

.news_sec .homenews .news_tag .fa {
    color: #ffffff;
    background: #fdcc02;
    height: 25px;
    border-top-left-radius: 10px;
    width: 25px;
    text-align: center;
    padding-top: 5px;
    border-bottom-left-radius: 10px;
}

.news_sec .homenews .news_tag .fa:hover {
    color: #000000;
}

.news_sec img {
    height: 100px;
    width: 100px;
    background: #f90;
    float: left;
    margin-right: 20px;
    border: solid 3px #fff;
    display: none;
}

.news_sec img {
    object-fit: cover;
}

.news_sec p {
    color: #fff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 63px;
    margin-top: 7px;
    line-height: 28px;
}

.news_sec h3 {
    color: #ffd400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 18px;
    line-height: 30px;
    font-weight: normal;
}

.news_sec .homenews .fa.colr_1 {
    background: #fb9902;
}

.news_sec .homenews .fa.colr_2 {
    background: #0f00f1;
}

.news_sec .homenews .fa.colr_3 {
    background: #f34343;
}

.news_sec .homenews .fa.colr_4 {
    background: #129602;
}

.news_sec .homenews .fa.colr_5 {
    background: #d800ff;
}
.slider_sec {
    position: relative;
    overflow: hidden;
}

.overlay_sec {
    position: absolute;
    height: 100%;
    background: rgb(0 0 0 / 19%);
    z-index: 9;
    width: 100%;
}

.button_container {
    position: fixed;
    /* top: 4%; */
    /* right: 85px; */
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.25s ease;
    color: #fff;
    font-weight: bold;
    z-index: 9999999;
    background: none !important;
    font-size: 16px;
}

.button_container:hover {
    opacity: 0.7;
}

.menu_btn.active .top {
    transform: translateY(11px) translateX(0) rotate(43deg);
    background: #000;
}

.menu_btn.active .middle {
    opacity: 0;
    background: #000;
}

.menu_btn.active .bottom {
    transform: translateY(-11px) translateX(0) rotate(312deg);
    background: #000;
}

.menu_btn span {
    background: #000000;
    border: none;
    height: 4px;
    width: 100%;
    position: absolute;
    top: -2px;
    left: 61px;
    transition: all 0.35s ease;
    cursor: pointer;
}

.menu_btn span:nth-of-type(2) {
    top: 9px;
}

.menu_btn span:nth-of-type(3) {
    top: 20px;
}

.overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/menu.jpg') #080607 no-repeat;
}

.overlay.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    padding: 150px;
    padding-top: 12px;
}


.overlay.open li:nth-of-type(2) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
    -webkit-animation-delay: 0.45s;
    animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.overlay nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    font-family: "Vollkorn", serif;
    font-weight: 400;
    text-align: center;
}

.overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
    max-width: 810px;
}

.overlay ul li {
    display: block;
    height: 25%;
    height: auto;
    min-height: 20px;
    position: relative;
    opacity: 0;
    float: left;
    width: 270px;
    text-align: left;
}

.overlay ul li a {
    display: block;
    position: relative;
    color: #ffeb00;
    text-decoration: none;
    overflow: hidden;
    line-height: 20px;
    text-align: left;
}

.overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
    width: 100%;
}

.overlay ul li a:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    transform: translateX(-50%);
    height: 3px;
    background: #FFF;
    transition: 0.35s;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}
.menu_new > div > .menuitem a {
    color: #FFF;
    min-width: 190px;
    transition: 0.5s, color 0.5s, transform 0.5s;
    margin: 10px 6px 3px 0px;
    padding: 13px 20px;
    border-radius: 3px;
    font-size: 17px;
    background: #404040;
    white-space: nowrap;
    display: block;
}
li.menuitem {
    position: relative;
    display: block;
    cursor: pointer;
    pointer-events: all;
    opacity: 1;
}
.menu_new >div {
    width: 300px;
}
.menu_new > div >.menuitem > .submenu {
    transition: transform 0.6s, opacity 0.6s;
    transform: translateY(150%);
    opacity: 0;
    position: absolute;
    left: 310px;
    top: 0;
}
.menu_new .fa-angle-right {
    float: right;
}
.menu_new > div >.menuitem:hover > .submenu {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.6s, opacity 0.6s;
}
.menu_new .submenu a {
    font-size: 17px !important;
    background: none !important;
    border-bottom: solid 1px #ffffff4d;
    padding: 17px 0px !important;
    line-height: 10px;
    color: #b5b5b5!important;
    position: relative;
}
li.menuitem>div:first-child {
    color: #FFF;
    min-width: 190px;
    transition: background 0.5s, color 0.5s, transform 0.5s;
    margin: 10px 6px 3px 0px;
    padding: 13px 20px;
    border-radius: 3px;
    font-size: 17px;
    background: #404040;
    white-space: nowrap;
    display: block;
}
.clr_change {
    color: #000;
    background: #fff;
}

.clr_change1 {
    color: #000;
    background: #000;
}

.pic-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: slideShow 110s linear infinite 0s;
    -o-animation: slideShow 110s linear infinite 0s;
    -moz-animation: slideShow 110s linear infinite 0s;
    -webkit-animation: slideShow 110s linear infinite 0s;
}

.pic-1 {
    opacity: 1;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld1.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-2 {
    animation-delay: 6s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld2.jpg') no-repeat center center;
    background-size: cover;
}

.pic-3 {
    animation-delay: 12s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld3.jpg') no-repeat center center;
    background-size: cover;
}

.pic-4 {
    animation-delay: 18s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld4.JPG') no-repeat center center;
    background-size: cover;
}

.pic-5 {
    animation-delay: 24s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld5.jpg') no-repeat center center;
    background-size: cover;
}

.pic-6 {
    animation-delay: 30s;
    -o-animation-delay: 30s;
    -moz--animation-delay: 30s;
    -webkit-animation-delay: 30s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld6.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-7 {
    animation-delay: 36s;
    -o-animation-delay: 36s;
    -moz--animation-delay: 36s;
    -webkit-animation-delay: 36s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld7.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-8 {
    animation-delay: 42s;
    -o-animation-delay: 42s;
    -moz--animation-delay: 42s;
    -webkit-animation-delay: 42s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld8.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-9 {
    animation-delay: 47s;
    -o-animation-delay: 47s;
    -moz--animation-delay: 47s;
    -webkit-animation-delay: 47s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld9.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-10 {
    animation-delay: 54s;
    -o-animation-delay: 54s;
    -moz--animation-delay: 54s;
    -webkit-animation-delay: 54s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld10.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-11 {
    animation-delay: 60s;
    -o-animation-delay: 60s;
    -moz--animation-delay: 60s;
    -webkit-animation-delay: 60s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld11.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-12 {
    animation-delay: 66s;
    -o-animation-delay: 66s;
    -moz--animation-delay: 66s;
    -webkit-animation-delay: 66s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld12.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-13 {
    animation-delay: 72s;
    -o-animation-delay: 72s;
    -moz--animation-delay: 72s;
    -webkit-animation-delay: 72s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld13.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-14 {
    animation-delay: 78s;
    -o-animation-delay: 78s;
    -moz--animation-delay: 78s;
    -webkit-animation-delay: 78s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld14.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.pic-15 {
    animation-delay: 84s;
    -o-animation-delay: 84s;
    -moz--animation-delay: 84s;
    -webkit-animation-delay: 84s;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/sld15.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.container-fluid.flwidth {
    width: calc(78% - 20px);
    background: #fff;
}
/* keyframes*/
@keyframes slideShow {
    0% {
        opacity: 0;
        transform: scale(1);
        -ms-transform: scale(1);
    }

    5% {
        opacity: 1
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        transform: scale(1.1);
        -ms-transform: scale(1.1);
    }

    100% {
        opacity: 0;
        transform: scale(1);
        -ms-transformm: scale(1);
    }
}

@-o-keyframes slideShow {
    0% {
        opacity: 0;
        -o-transform: scale(1);
    }

    5% {
        opacity: 1
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        -o-transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -o-transformm: scale(1);
    }
}

@-moz-keyframes slideShow {
    0% {
        opacity: 0;
        -moz-transform: scale(1);
    }

    5% {
        opacity: 1
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        -moz-transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -moz-transformm: scale(1);
    }
}

@-webkit-keyframes slideShow {
    0% {
        opacity: 0;
        -webkit-transform: scale(1);
    }

    5% {
        opacity: 1
    }

    25% {
        opacity: 1;
    }

    30% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }

    100% {
        opacity: 0;
        -webkit-transformm: scale(1);
    }
}

.about_Sec {
    margin: 0px;
}

.about_Sec .aboutbox {
    margin-bottom: 40px;
}

.about_Sec .aboutbox p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 65px;
}

.about_Sec .aboutbox h3 {
    margin-bottom: 5px;
    color: #e42c70;
    font-size: 19px;
}

.about_Sec .aboutbox h3 a {
    color: #d41259;
}

.about_Sec .aboutbox h3 a:hover {
    color: #000;
}

.about_Sec .aboutbox img {
    width: 50px;
    float: left;
    margin-right: 10px;
}

.about_Sec iframe {
    background: #000000;
    padding: 10px;
    padding-bottom: 10px;
    height: 390px;
}

.motto_sec {
    margin-top: 30px;
}

.motto_sec h2 {
    margin-bottom: 50px;
    text-align: left;
    letter-spacing: 1px;
}

.highligh_sec {
    background: #0a6528;
    height: 32px;
    position: relative;
    overflow: hidden;
}
.highligh_sec marquee a, .highligh_sec marquee {
    color: #fff !important;
}

.highligh_sec h4 {
    position: absolute;
    background: #0d4b23;
    height: 100%;
    z-index: 9;
    font-size: 17px;
    line-height: 32px;
    padding-right: 5px;
    padding-left: 5px;
    letter-spacing: 1px;
    font-weight: normal;
}

.highligh_sec h4 a {
    color: #fff;
}

.highligh_sec ul {
    position: absolute;
    padding-right: 10px;
    background: #285837;
    z-index: 9;
    right: 0px;
    padding-left: 10px;
    height: 32px;
    padding-top: 2px;
}

.highligh_sec ul li {
    display: inline-block;
    margin-top: 2px;
}

.highligh_sec ul li a {
    margin: 0px;
}

.highligh_sec ul li .fab {
    width: 25px;
    height: 22px;
    margin-right: 5px;
    color: #fff;
}

.highligh_sec ul li .fab:hover {
    color: #fce900;
}

.highligh_sec marquee p {
    margin: 7px 2px;
    line-height: 18px;
    color: #fff;
    display: inline-block;
    padding: 0 10px;
    border-right: 2px solid #fff;
}
.highligh_sec marquee p:last-child {
    border-right: 0px;
}
.highligh_sec marquee p a {
    width: 25px;
    height: 25px;
    padding: 10px;
}

.highlights_sec marquee p a:hover {
    color: #f99a6a;
}

.highligh_sec marquee p .fa {
    /* background: #ffffff; */
    padding: 5px 5px;
    color: #ffba00;
    border-radius: 15px;
    font-size: 14px;
}

.highligh_sec marquee p .fa:hover {
    color: #fff;
}

.logo_sec {
    /* background: #ffffff; */
    /* background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/BG.jpg'); */
}

.logo_sec li .fas {
    color: #e53777;
    margin-bottom: 10px;
    font-size: 18px;
}

.logo_sec li span {
    display: inline-block;
    width: 100%;
    margin-top: 5px;
}

.logo_sec li .fas:hover {
    color: #208597;
}

.logo_sec img {
    width: 440px;
    /* float: left; */
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 55px;
}

.logo_sec ul {
    float: right;
    text-align: center;
    margin-top: 37px;
}

.logo_sec ul li {
    display: inline-block;
    margin: 0px 10px;
    font-size: 15px;
    font-family: 'NovaFlat-Regular';
}

.logo_sec ul li a.online_reg {
    color: #fff;
    animation: blinker .8s linear infinite;
    background: #e53476;
    padding: 3px;
    padding-right: 5px;
    line-height: 18px;
    font-size: 16px;
    padding-left: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@keyframes blinker {
    50% {
        background: #2f56e1;
    }
}

.logo_sec ul li a {
    margin: 0px;
    color: #000;
}

.menu_sec {
    background: 0px;
    /* border-top: solid 1px #e;background: #fdcc02;;00000061; */
    background: rgb(185 13 76);
}

.menu_sec ul {
    text-align: center;
}

.menu_sec ul li {
    display: inline-block;
    /* background: #e53777; */
}

.menu_sec {
    background: 0px;
    border-top: solid 1px #eee;
    background: #101012;
}

.menu_sec ul {
    text-align: center;
    margin-bottom: 0;
}

.menu_sec ul li {
    display: inline-block;
    /* background: #e53777; */
}
.dropdown__item a.nav__link.active {
    background: #e53777;
}
.nav__content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.nav__perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.nav__img {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.nav__img img {
    width: 70px;
}

.nav__name {
    display: block;
    font-size: var(--nav-name-font-size);
    color: var(--white-color);
}

.nav__item {
}

.nav__link {
    color: #fff;
}

.nav__link:hover {
    color: #ffe000;
}

/*Show menu*/
.show {
    left: 0;
}

/*Active link*/
.active {
    color: var(--white-color);
}

.dropdown__link {
    align-items: center;
    justify-content: space-between;
}

.dropdown__icon {
    font-size: 15px;
    transition: .5s;
    margin-left: 3px;
    /* display: none; */
}

.dropdown__menu {
    margin: 1rem 0 0 1rem;
    display: none;
}

.dropdown__item {
    width: 100%
}

.dropdown:hover > .dropdown__menu {
    display: block;
}

.dropdown:hover .dropdown__icon {
    transform: rotate(180deg);
}

.nav__content {
    display: flex;
    flex-direction: column;
}

.nav__perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.nav__img {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.nav__img img {
    width: 70px;
}

.nav__name {
    display: block;
    font-size: var(--nav-name-font-size);
    color: var(--white-color);
}

.nav__item {
}

.nav__link {
    color: var(--first-color-light);
}

.nav__link:hover {
    color: var(--white-color);
}

/*Show menu*/
.show {
    left: 0;
}

/*Active link*/
.active {
    color: var(--white-color);
}

.dropdown__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown__icon {
    font-size: 1.3rem;
    transition: .5s;
}

.dropdown__menu {
    margin: 1rem 0 0 1rem;
    display: none;
}

.dropdown__item {
    /* margin: 1rem 0; */
}

.dropdown:hover > .dropdown__menu {
    display: block;
}

.dropdown:hover .dropdown__icon {
    transform: rotate(180deg);
}

.submenu ul {
    display: none;
    position: absolute;
    left: 235px;
    background: #0c3a71;
    width: 250px;
    top: 18px;
}

.submenu .sub:hover + .submenu ul {
    display: block !important;
}

.menu_right_algn {
    right: 0px;
}

.nav__link {
    color: #000;
    font-size: 13px;
    font-weight: normal;
}

.event_importnt_sec {
    background: #eee;
}

.event_importnt_sec h2 {
    margin-bottom: 20px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.event_importnt_sec h2 .readmore {
    margin: 0px;
    line-height: 20px;
    float: right;
}

.event_sec {
    /* background:url('https://webapi.entab.info/api/image/LVISGN/public/Images/bg.jpg') center repeat; */
    margin-top: 44px;
}

.event_sec .colr_sec {
    background: linear-gradient(rgba(232,78,188,0.85),rgba(0,158,219,0.93));
    padding-top: 50px;
    padding-bottom: 50px
}

.event_sec .event_pic {
    width: 320px;
    height: 340px;
    margin: auto;
    bottom: 0;
    margin-top: 80px
}

.event_sec div.main {
    width: auto;
    height: 43px;
    overflow: hidden;
    top: -45px
}

.event_sec div.main:hover > .sub {
    bottom: 55px
}

.event_sec div.sub {
    overflow: hidden;
    position: relative;
    bottom: -140px;
    width: auto;
    background-color: #e42c70;
    -webkit-transition: bottom .8s ease;
    -moz-transition: bottom .8s ease;
    -o-transition: bottom .8s ease;
    -ms-transition: bottom .8s ease;
    transition: bottom .8s ease;
}

.event_sec div.sub p {
    padding: 10px;
    margin-top: 0;
    overflow-y: scroll;
    display: block;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: vertical;
    height: 110px;
    margin-bottom: 0
}

.event_sec .draw-border {
    margin: 0 10px;
    background: #fff;
    padding: 30px;
    border: solid 1px #e6e6e6
}

.event_sec .draw-border .fa-paperclip {
    background: #e42c70;
    padding: 5px 15px;
    color: #fff;
    position: absolute;
    z-index: 9;
    top: 30px;
    left: 30px;
}

.event_sec .draw-border h3 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 58px;
    line-height: 28px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: 17px;
}

.event_sec .draw-border p {
    text-align: center;
    background: #fff;
    border: solid 1px #ddd;
    cursor: pointer
}

.event_sec .draw-border ul {
    padding-left: 0;
    margin-bottom: 0;
    text-align: center
}

.event_sec .draw-border ul li {
    margin-left: 5px;
    line-height: 23px;
    list-style-type: none;
    color: #fff;
    font-size: 13px;
    display: inline-block;
    padding: 10px 0px;
}

.event_sec .draw-border ul li i {
    color: #ffcf00
}

.event_sec .draw-border img {
    margin: 0px;
}

.event_sec .draw-border img {
    object-fit: cover;
    height: 258px;
    width: 100%;
}

.important_sec article {
    margin: 0px;
    padding: 20px;
    background: #f2f2f2;
    height: 530px;
    width: 97%;
}

.important_sec h2 {
    margin: 0px;
}

.important_sec article {
    margin: 0px;
}

.important_sec article p {
    line-height: 25px;
    margin: 15px auto;
}

.important_sec article ul {
    margin: 0px;
}

.important_sec article ul li {
    background: #fff;
    margin-bottom: 20px;
    height: 50px;
    font-weight: bold;
}

.important_sec article ul li .fas {
    background: #888;
    height: 50px;
    width: 50px;
    color: #fff;
    font-size: 25px;
    text-align: center;
    padding-top: 10px;
}

.important_sec article ul li a {
    color: #000;
    padding-left: 10px;
}

.important_sec article ul li a:hover {
    color: #f52571;
}

.important_sec article .fa {
    margin: 0px;
    color: #fff;
    font-size: 35px;
    background: #f00;
    width: 55px;
    height: 55px;
    text-align: center;
    padding-top: 8px;
    float: left;
    margin-right: 30px;
}

.important_sec article .fas.colr_1 {
    background: #fb9902;
}

.important_sec article .fas.colr_2 {
    background: #0f00f1;
}

.important_sec article .fas.colr_3 {
    background: #f34343;
}

.important_sec article .fas.colr_4 {
    background: #129602;
}

.important_sec article .fas.colr_5 {
    background: #d800ff;
}

.testimonial_sec .testi_1 .fa-quote-left {
    background: #fb9902;
    width: 65px;
    height: 65px;
    padding-top: 18px;
    color: #fff;
    font-size: 27px;
    border-radius: 100%;
    text-align: center;
    display: block;
    position: relative;
}

.testimonial_sec .testi_2 .fa-quote-left {
    background: #f34343;
    width: 65px;
    height: 65px;
    padding-top: 18px;
    color: #fff;
    font-size: 27px;
    border-radius: 100%;
    text-align: center;
    display: block;
    position: relative;
}

.testimonial_sec .testi_3 .fa-quote-left {
    background: #159601;
    width: 65px;
    height: 65px;
    padding-top: 18px;
    color: #fff;
    font-size: 27px;
    border-radius: 100%;
    text-align: center;
    display: block;
    position: relative;
}

.testimonial_sec .testi_4 .fa-quote-left {
    background: #d800ff;
    width: 65px;
    height: 65px;
    padding-top: 18px;
    color: #fff;
    font-size: 27px;
    border-radius: 100%;
    text-align: center;
    display: block;
    position: relative;
}

.testimonial_sec  {
    background: linear-gradient(110deg, #115a29e6 43%, #05421ae8 50%);
    padding-top: 60px;
    padding-bottom: 60px;
}

.testimonial_sec .testi_1 .fas {
    display: block;
    text-align: center;
    color: #ffbc00;
}

.testimonial_sec .testi_1 {
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/yellow.png') no-repeat center;
}

.testimonial_sec .testi_2 {
    margin-top: 40px;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/pink.png') no-repeat center;
}

.testimonial_sec .testi_3 {
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/green.png') no-repeat center;
}

.testimonial_sec .testi_4 {
    margin-top: 40px;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/blue.png') no-repeat center;
}

.testimonial_sec {
    margin-top: 0px;
    background-position: initial;
}

.testimonial_sec h2 {
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 60px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.testimonial_sec .testi_1 {
    margin: 0px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    padding-top: 100px;
    width: 100%;
    background-size: 100% 100%;
}
div#exampleModalToggle .slide {
    opacity: 1;
    position: static;
    animation: unset;
}
.testimonial_sec .testi_1 .data_sec {
    margin: 0px;
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 23px;
    border-radius: 20px;
    margin-top: -10px;
}

.testimonial_sec .testi_1 .data_sec .fa-quote-left {
    font-size: 45px;
}

.testimonial_sec .testi_1 h4 {
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 0px;
    text-align: center;
    line-height: 27px;
    font-size: 14px;
    width: 85%;
    margin: auto;
    font-weight: bold;
    position: relative;
    top: -60px;
}

.testimonial_sec .testi_1 p {
    margin: 0px;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    height: 180px;
    line-height: 29px;
    /* color: #ffffff; */
    margin-bottom: 25px;
    padding: 15px;
    margin-top: 5px;
    position: relative;
    top: -47px;
    font-size: 15px;
}



.testimonial_sec .testi_1.video-testi h4 {
    top: -52px;
}

.testimonial_sec .testi_1.video-testi iframe {
    position: relative;
    top: -35px;
    height: 180px;
}


.testi_sec .testimonialsbox {
    background: #fff4f8;
    padding: 20px;
    margin-bottom: 10px;
    border: dotted 1px #d8d8d8;
}

.testi_sec .testimonialsbox p {
    margin-bottom: 20px;
}

.testi_sec .testimonialsbox h4 {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
}


.month-selection .active_month {
    border: solid 1px #d41259;
}

.myDiv {
    display: none;
}

.selected_year {
    display: block;
}

#ddlYear {
    border: solid 1px #d2d2d2;
    color: #001a52;
    width: 200px;
    height: 31px;
    padding: 5px;
    margin-top: 0px;
    background: #fff2ec;
    margin-bottom: 15px;
}

.year_selction_sec {
    float: right;
    padding: 0px 15px;
    margin-top: -46px;
}

.year_selction_sec select {
    margin: 0px;
}

.month-selection {
    margin: 0px;
}

.month-selection ul {
    text-align: center;
}

.month-selection ul li {
    display: inline-block;
    border: solid 1px #dedede;
    padding: 0px 10px;
    text-transform: capitalize;
}

div[data-trigger] {
    display: none;
}

.gallery_sec h2 {
    margin-bottom: 25px;
    text-align: center;
}

.gallery_sec .homegallerybox:hover .gal_data_sec {
    background-color: rgb(1 31 47 / 80%);
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.gallery_sec {
    padding-top: 50px;
    padding-bottom: 50px;
}

.gallery_sec h1 {
    margin-bottom: 25px;
}

.gal_row {
    margin: 0px -15px;
}

.gallery_sec .homegallerybox {
    position: relative;
    margin-bottom: 20px;
}

.gallery_sec img {
    display: block;
    width: 100%;
    height: 200px;
}

.gallery_sec img {
    object-fit: contain;
    object-position: center;
}

.gallery_sec .big_img {
    height: 420px;
}

.gallery_sec .gal_data_sec .date_sec {
    background: #bb0245;
    width: 85px;
    color: #fff;
    padding: 9px;
    font-size: 12px;
    right: 0px;
    position: absolute;
    bottom: 0px;
    text-align: right;
}

.gallery_sec .gal_data_sec .date_sec .fas {
    width: 35px;
    height: 35px;
    position: absolute;
    left: -34px;
    background: #f52571;
    /* border-radius: 100%; */
    padding-top: 10px;
    text-align: center;
    bottom: 0px;
    color: #ffffff;
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}

.gallery_sec .gal_data_sec .date_sec .fas:hover {
    transform: rotate(360deg);
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    background: #faca07;
}

.gallery_sec .gal_data_sec {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgb(0 20 32 / 59%);
}

.gallery_sec h4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 28px;
    font-size: 21px;
    color: #fff;
    padding-left: 15px;
    margin-top: 10px;
    font-weight: normal;
    text-shadow: 1px 2px 1px black;
}

.gallery_sec h5 {
    margin: 0px;
    padding-left: 18px;
    margin-top: 3px;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    text-shadow: 1px 2px 1px black;
}


.office_time_sec  {
    background: linear-gradient(110deg, #538e65 50%, #3e6b4d 50%);
    padding-top: 50px;
    padding-bottom: 50px;
    /* height: 45vh; */
}

.office_time_sec h3 {
    font-size: 25px;
    color: #fff;
    text-align: center;
    line-height: 55px;
}

.office_time_sec p {
    text-align: center;
    color: #ffff;
    line-height: 40px;
    letter-spacing: 1px;
}

.office_time_sec p a {
    color: #fff;
}

.office_time_sec p a:hover {
    color: #fccb07;
}

.office_time_sec img {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    display: block;
    text-align: center;
}

.footer_sec {
    background: #fff;
    padding-top: 60px;
    padding-bottom: 20px;
}

.ftr_logo {
    width: 150px;
    margin-top: -130px;
    margin-bottom: 60px;
    position: relative;
    top: -12px;
}

.footer_sec h3 {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.footer_sec p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 100px;
    margin-bottom: 0px;
    color: #000;
}

.footer_sec ul {
    margin-top: 10px;
    padding-left: 0;
}

.footer_sec ul li {
    list-style-type: none;
    display: inline-block;
}

.quick_links {
    margin: 0px;
}

.quick_links li {
    display: block !important;
    line-height: 32px;
    list-style-type: disc !important;
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/tick.png') no-repeat 0%;
    background-position: left;
    padding-left: 30px;
    font-size: 15px;
}

.quick_links li a {
    color: #000;
}

.quick_links li a:hover {
    color: #f52571;
}

.footer_sec ul li a {
    margin: 0px;
}

.footer_sec ul li a i:hover {
    background: #d41259;
}

.footer_sec ul li a i {
    margin: 0px;
    font-size: 20px;
    color: #fff;
    background: #f1951d;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    display: block;
    padding-top: 11px;
    transition: 0.8s;
}

.mobile_app {
    float: left;
    margin-left: 5%;
}

.app_title {
    margin-left: 15%;
}

.mobile_app_link {
    float: left;
    margin-left: 20px;
    margin-top: 7px;
}

.mobile_app img {
    width: 80px;
    margin: 0 auto;
    margin-top: 0px;
    float: none;
}

.mobile_app_link img {
    float: left;
}

.mobile_app_link h5 {
    margin-top: 20px;
    font-size: 15px;
}

.contact_ftr p {
    height: auto;
}

.contact_ftr a {
    color: #000;
}

.draw-border {
    -webkit-transition: color 0.5s 0.0833333333s;
    transition: color 0.5s 0.0833333333s;
    position: relative;
}

.draw-border::before, .draw-border::after {
    border: 0 solid #e42c70;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
}

.draw-border::before {
    border-bottom-width: 2px;
    border-left-width: 2px;
}

.draw-border::after {
    border-top-width: 2px;
    border-right-width: 2px;
}

.draw-border:hover::before, .draw-border:hover::after {
    border-color: #f52571;
    -webkit-transition: border-color 0s, width 0.5s, height 0.5s;
    transition: border-color 0s, width 0.5s, height 0.5s;
    width: 100%;
    height: 100%;
}

.draw-border:hover::before {
    -webkit-transition-delay: 0s, 0s, 0.5s;
    transition-delay: 0s, 0s, 0.5s;
}

.draw-border:hover::after {
    -webkit-transition-delay: 0s, 0.5s, 0s;
    transition-delay: 0s, 0.5s, 0s;
}

.footer_sec_btm {
    background: #4c835d;
    line-height: 25px;
}

.footer_sec_btm p {
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    margin-bottom: 0;
}

.footer_sec_btm p a {
    color: #ffffff;
}

.footer_sec_btm p a:hover {
    color: #ffd400;
}

.header__logo {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.bd-grid {
    display: grid;
    grid-template-columns: 100%;
}

.header__toggle {
    font-size: 20px;
    cursor: pointer;
    float: right;
    color: #fff;
    line-height: 25px;
    padding-right: 20px;
}

.nav__content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.nav__perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.nav__img {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.nav__img img {
    width: 70px;
}

.nav__name {
    display: block;
    font-size: var(--nav-name-font-size);
    color: var(--white-color);
}

.nav__item {
}

.nav__link {
    color: #fff;
}

.nav__link:hover {
    color: #ffe000;
}

/*Show menu*/
.show {
    left: 0;
}

/*Active link*/
.active {
    color: var(--white-color);
}

.dropdown__link {
    align-items: center;
    justify-content: space-between;
}

.dropdown__icon {
    font-size: 15px;
    transition: .5s;
    margin-left: 3px;
    /* display: none; */
}

.dropdown__menu {
    margin: 1rem 0 0 1rem;
    display: none;
}

.dropdown__item {
    width: 100%
}

.dropdown:hover > .dropdown__menu {
    display: block;
}

.dropdown:hover .dropdown__icon {
    transform: rotate(180deg);
}

.nav__content {
    display: flex;
    flex-direction: column;
}

.nav__perfil {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
}

.nav__img {
    display: flex;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.nav__img img {
    width: 70px;
}

.nav__name {
    display: block;
    font-size: var(--nav-name-font-size);
    color: var(--white-color);
}

.nav__item {
}

.nav__link {
    color: var(--first-color-light);
}

.nav__link:hover {
    color: var(--white-color);
}

/*Show menu*/
.show {
    left: 0;
}

/*Active link*/
.active {
    color: var(--white-color);
}

.dropdown__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown__icon {
    font-size: 1.3rem;
    transition: .5s;
}

.dropdown__menu {
    margin: 1rem 0 0 1rem;
    display: none;
}

.dropdown__item {
    /* margin: 1rem 0; */
}

.dropdown:hover > .dropdown__menu {
    display: block;
}

.dropdown:hover .dropdown__icon {
    transform: rotate(180deg);
}

.submenu ul {
    display: none;
    position: absolute;
    left: 235px;
    background: #0c3a71;
    width: 250px;
    top: 18px;
}

.submenu .sub:hover + .submenu ul {
    display: block !important;
}

.nav__link {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    /* background: rgb(185 13 76 / 57%); */
    /* border-radius: 10px; */
}

.btn_links {
    position: fixed;
    top: 250px;
    display: block;
    z-index: 9;
    left: 0px;
    padding-left: 0px;
}

.btn_links li {
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    list-style-type: none;
    line-height: 10px;
}

.btn_links li a {
    padding: 10px;
    background: #e53777;
    margin-bottom: 10px;
    display: inline-block;
    width: 200px;
    margin-left: -190px;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    text-align: right;
    position: relative;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
}

.btn_links li a:hover {
    margin-left: 0px;
    color: #ffffff;
    font-size: 18px;
}

.btn_links li .fas {
    background: #b90d4c;
    height: 40px;
    position: absolute;
    top: 0px;
    padding-top: 9px;
    width: 40px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    font-size: 20px;
}

.btn_links li .fa-graduation-cap {
    background: #4f795c;
}

.btn_links .blink_1 {
    animation: blinker .3s linear infinite;
    background: #ff1600;
}
.socialWork img {
    height: 300px;
    width: 100%;
    object-fit: contain;
    background: #ddd;
    box-shadow: 0px 0px 17px -10px #000;
    border: 5px solid #fff;
}
@keyframes blinker {
    50% {
        background: #2f56e1;
    }
}

.mob_view {
    display: none;
}

.erp_links {
    position: fixed;
    bottom: 50px;
    z-index: 999;
    left: 0px;
    width: 80px;
}

.erp_links img {
    width: 80px;
}

.over_effect {
    transition: all  1s  0.5s;
    cursor: pointer;
    transition: all 3s;
}
.mobile_app_link a {
    margin: 5px;
    display: inline-block;
}
.footer_sec ul li {
    margin: 5px;
}
.over_effect:hover {
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    transform: translate3d(0, -20px, 0);
    -webkit-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
}
  table {
  text-align: center;
  }

.inner_page {
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/innerbg.jpg') repeat;
}

.inner_page .container-fluid {
    background: #fff;
    padding-bottom: 50px;
    min-height: 650px;
}

.inner_page .bgimage {
    background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/menu2.jpg') bottom no-repeat;
    height: auto;
    margin-bottom: 50px;
}

.inner_page h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 25px;
    text-transform: uppercase;
    width: 492px;
    margin: auto;
    height: 152px;
    padding-top: 50px;
    margin-top: 40px;
    background: #d41259;
    width: 100%;
    height: auto;
    padding-top: 0px;
    line-height: 65px;
    color: #fff;
    font-weight: bold;
    font-family: Merriweather-Black;
}

.inner_page strong {
    color: #4f795c;
}

.inner_page h5 {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: normal;
    margin-bottom: 15px;
    line-height: 45px;
    padding-top: 65px;
}

.inner_page h5 a {
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: normal;
}

.inner_page h3 {
    line-height: 40px;
    font-size: 20px;
    color: #d82063;
    font-weight: normal;
    margin-top: 15px;
}

.inner_page p {
    font-size: 16px;
    color: #2d2d2d;
    line-height: 35px;
    margin-bottom: 15px;
    font-family:  "Poppins", sans-serif;
}

.inner_page .large_fnt {
    font-size: 20px;
}

.inner_page ul {
    padding: 13px 33px;
}

.inner_page ul li {
    list-style-type: circle;
    font-size: 15px;
    color: #2d2d2d;
    line-height: 25px;
    margin-bottom: 14px;
    font-family:  "Poppins", sans-serif;
}

.inner_page ul li a {
    color: #001227;
}

.inner_page ul li a:hover {
    color: #df1a1c;
}

.inner_img {
    float: left;
    width: 450px !important;
    margin-right: 35px;
    margin-bottom: 20px;
    border: solid 5px #ffe2e2;
}

.inner_img img {
    object-fit: cover;
    object-position: center;
}

.inner_img_small {
    float: left;
    width: 200px;
    margin-right: 35px;
    margin-bottom: 20px;
    border: solid 5px #ffe2e2;
}

.inner_img_small img {
    object-fit: cover;
    object-position: center;
}

.inner_img_right {
    float: right;
    width: 450px;
    margin-left: 35px;
    margin-bottom: 20px;
    border: solid 5px #ffe2e2;
}

.inner_img_right img {
    object-fit: cover;
    object-position: center;
}

.innertable {
    overflow: auto;
    margin-bottom: 25px;
    font-size: 15px;
    color: #2d2d2d;
    line-height: 31px;
}

.innertable td {
    border: solid 1px #efefef;
    padding: 5px;
    font-size: 15px;
    color: #333;
}

.innertable td img {
    width: 75px;
    height: 65px;
}

.innertable th {
    background: rgb(10 101 40);
    border: solid 1px #fcf4ff;
    padding: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    line-height: 26px;
}

.innertable tr:nth-child(even) {
    background-color: #f3f3f3;
}

.achivemnt_sec .achievementbox::-webkit-scrollbar {
    width: 5px;
}

.achivemnt_sec .achievementbox::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.achivemnt_sec .achievementbox::-webkit-scrollbar-thumb {
    background: #eee;
    border-radius: 10px;
}

.achivemnt_sec .achievementbox::-webkit-scrollbar-thumb:hover {
    background: #ddd;
}

.achivemnt_sec .achievementbox {
    padding: 14px;
    margin-top: 10px;
    position: relative;
    background: #f3f3f3;
    margin-bottom: 20px;
    max-height: 420px;
    overflow-x: auto;
}

.achivemnt_sec .achievementbox h3 {
    text-align: center;
    color: #000;
    margin-top: 0;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 37px;
    line-height: 40px!important
}

.achivemnt_sec .achievementbox p {
    overflow: hidden;
    line-height: 25px;
    margin-top: 45px;
    font-size: 15px;
}

.achivemnt_sec .achievementbox img {
    width: 250px;
    margin: auto;
    margin-bottom: 20px;
    cursor: pointer;
    height: 260px;
    display: block;
}

.achivemnt_sec .achievementbox ul {
    text-align: center
}

.achivemnt_sec .achievementbox ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 7px;
    line-height: 35px;
}

.achivemnt_sec .achievementbox ul li .fa {
    color: #7d7d7d;
}

.achivemnt_sec .achievementbox .data_inner_sec {
    position: absolute;
    width: 100%;
    background: #e6e6e6;
    left: 0;
    top: 140px;
    height: 33px
}

.achivemnt_sec div.main {
    position: relative;
    width: auto; 
    overflow: hidden;
    top: -45px;
}
.imgdiv {
    display: flex;
}
.achivemnt_sec div.main:hover > .sub {
    bottom: -23px;
}

.achivemnt_sec div.sub {
    position: absolute;
    bottom: -140px;
    width: 100%;
    height: 114px;
    background-color: #fff;
    -webkit-transition: bottom .8s ease;
    -moz-transition: bottom .8s ease;
    -o-transition: bottom .8s ease;
    -ms-transition: bottom .8s ease;
    transition: bottom .8s ease;
    display: none;
}

.achivemnt_sec div.sub p {
    padding: 10px;
    margin-top: 0;
    overflow-y: scroll;
    display: block;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: vertical;
    height: 110px;
    margin-bottom: 0
}
.imgdiv img {
    width: 203px !important;
}
.board_members_sec .advisory {
    border: dotted 1px #fccc00;
    background: #f7f3e3;
    padding: 10px;
    margin-bottom: 20px;
}

.board_members_sec .advisory  img {
    float: left;
    height: 150px;
    width: 150px;
    border-radius: 100%;
    margin-right: 40px;
}

.board_members_sec .advisory p {
    margin: 0px;
}

.board_members_sec .advisory h4 {
    line-height: 40px;
    color: #f14284;
}

.experiential_sec h4 {
    text-align: center;
    line-height: 25px;
    font-size: 20px;
}

.experiential_sec img {
    width: 100%;
    border: solid 5px #efefef;
    margin-bottom: 20px;
}


.panelstyle p {
    padding: 10px;
}

.panelstyle {
    border: solid 1px #eee;
    padding-bottom: 0px;
}

.img_data_sec .box {
    background: #ffd3e5;
    margin-bottom: 20px;
}

.img_data_sec .box h4 {
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
}

.img_data_sec img {
    width: 100%;
    border: solid 5px #efefef;
    margin-bottom: 0px;
}

.img_data_sec img {
    object-fit: cover;
    height: 230px;
}

.art_craft_img {
    width: 650px;
    border: solid 5px #ffe2e2;
}

.art_craft_img img {
    object-fit: cover;
}

.booklist li {
    padding-left: 5px;
    font-size: 14px !important;
}

.pdf_sec h5 {
    text-align: center;
    font-size: 13px;
    text-decoration: none;
    position: absolute;
    top: 0px;
    left:0px;
    background: #e81c66;
    line-height: 25px;
    padding-top: 0px;
    padding-left: 5px;
    color: #fff;
    padding-right: 5px;
}

.pdf_sec h4 {
    text-align: center;
    padding-top: 15px;
    font-size: 14px;
    text-decoration: none;
    line-height: 25px;
    overflow: hidden;
    font-weight: bold;
    /* display: -webkit-box; */
    -webkit-line-clamp: 2;
    height: 65px;
    text-transform: uppercase;
}

.pdf_sec img {
    width: 35px;
    height: 35px;
    display: block;
    margin: auto;
}

.pdf_sec .box {
    margin: auto;
    margin-bottom: 20px;
    border: solid 1px #ece2ff;
    background: #fbedf2;
    padding: 10px;
    position: relative;
}

.pdf_sec .box a {
    color: #000;
}

.pdf_sec .box h4:hover {
    color: #a93a00;
}

.gallery_sec {
    padding-top: 50px;
    padding-bottom: 50px;
}

.gallery_sec h1 {
    margin-bottom: 50px;
}

.gallery_sec .homegallerybox {
    overflow: hidden;
    margin-bottom: 25px;
}

.gallery_sec .img_sec {
    opacity: 1;
    display: block;
    width: 100%;
    height: 215px;
    transition: .5s ease;
    backface-visibility: hidden;
    position: relative;
}

.gallery_sec .img_sec img {
    opacity: 1;
    display: block;
    width: 100%;
    height: 215px;
    transition: .5s ease;
    backface-visibility: hidden;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.gallery_sec .img_sec img {
object-fit: cover;
}

.data_overlay_sec {
transition: .5s ease;
opacity: 0;
position: absolute;
top: 0px;
text-align: center;
background: rgb(241 111 168 / 68%);
height: 216px;
width: 100%;
padding-top: 90px;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
right: 0px;
left: 0px;
bottom: 0px;
}

.data_overlay_sec .event_atachment {
color: #fff;
margin-top: 10px;
}

.data_overlay_sec .event_atachment .fa {
color: #fcb930;
}

.data_overlay_sec .event_atachment a {
color: #fcb930;
}

.data_overlay_sec .event_atachment a:hover {
color: #fff;
}

.gallery_sec .homegallerybox:hover .img_sec {
opacity: 1;
}

.gallery_sec .homegallerybox:hover .data_overlay_sec {
opacity: 1;
}

.gallery_sec .homegallerybox h3 {
margin: 15px auto;
}

.gallery_sec .homegallerybox p {
margin: auto;
}

.gallery_sec .homegallerybox h6 {
border-top: solid 1px #ddd;
padding-top: 10px;
border-bottom: solid 1px #ddd;
padding-bottom: 10px;
text-align: center;
margin-top: 10px;
letter-spacing: 2px;
color: #8e8e8e;
font-family: Roboto-Regular;
}

.contact_page .fa, .contact_page .school {
float: left;
background: #e13473;
width: 50px;
height: 50px;
border-radius: 100%;
text-align: center;
color: #fff;
padding-top: 15px;
font-size: 20px;
margin: auto;
margin-right: 20px;
margin-bottom: 35px;
}

.contact_page h3 {
color: #112e16;
font-size: 21px;
margin-top: 0px;
margin-bottom: 0px;
}

.contact_page p {
margin-bottom: 15px;
}

.contact_page img {
width: 400px;
}

.contact_page a {
color: #000;
}

.contact_page a:hover {
color: #000;
}

.tcsec article {
background: #ffffff;
padding: 10px;
border: solid 1px #ffffff;
min-height: 300px;
margin-bottom: 20px;
}

.tcsec article img {
width: 400px;
margin-bottom: 0px;
}

.form_sec input[type="text"] {
width: 100%;
height: 40px;
padding-left: 45px;
margin-bottom: 20px;
border: solid 1px #ccc;
background: #e6e6e6;
}

.form_sec textarea {
width: 100%;
height: 60px;
resize: none;
border: solid 1px #c7c7c7;
padding-left: 45px;
background: #e6e6e6;
}

.form_sec select {
width: 100%;
height: 60px;
resize: none;
border: solid 1px #cacaca;
padding-left: 45px;
background: #e6e6e6;
}

.form_sec .fas, .form_sec .far {
position: absolute;
left: 10px;
top: 10px;
border-right: solid 1px #ddd;
padding-right: 10px;
height: 20px;
}

.form_sec .text_box_sec {
position: relative;
}

.form_sec input[type="submit"] {
width: 150px;
height: 40px;
margin-top: 20px;
background: #4f795c;
color: #fff;
font-size: 14px;
border: solid 1px #fff;
text-transform: uppercase;
margin-bottom: 20px;
cursor: pointer;
margin: auto;
display: block;
}

.form_sec input[type="submit"]:hover {
background: #0280c3;
}

.partners_sec {
padding-top: 50px;
padding-bottom: 50px;
}

.partners_sec img {
    height: 100%;
}
.partnerbox {
    background: #fff;
    box-shadow: 1px 2px 8px 0px #000000;
    padding: 10px;
    border-radius: 10px;
    height: 120px;
}

.link_sec {
margin-top: 60px;
}

.link_sec h2 {
    text-align: center;
font-weight: 600;
margin-top: 20px;
}

.link_sec .draw-border {
height: 250px;
background: linear-gradient(110deg, #de1a62 50%, #d21057 50%);
border: solid 3px #efefef;
padding: 30px 20px;
}

.link_sec .draw-border h3 {
text-align: center;
font-size: 25px;
margin-bottom: 17px;
font-size: 19px;
text-transform: uppercase;
}

.link_sec .draw-border h3 a {
color: #fff;
}

.link_sec .draw-border h3 a:hover {
color: #f9d538;
}

.link_sec .draw-border p { 
    /* -webkit-box-orient: vertical; */
    color: #fff;
    /* display: -webkit-box; */
    font-size: 15px;
    /* height: 126px; */
    line-height: 29px;
    overflow: hidden;
}

.image {
opacity: 1;
display: block;
width: 100%;
height: 250px;
transition: .5s ease;
backface-visibility: hidden;
border: solid 1px #fff;
}

.image img {
object-fit: cover;
}

.middle_img {
transition: .5s ease;
opacity: 1;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
text-align: center;
}

.link_sec .draw-border:hover .image {
opacity: 0.3;
}

.link_sec .draw-border:hover .middle_img {
opacity: 1;
}

.link_sec .draw-border .text {
background-color: #b90d4c;
color: white;
font-size: 16px;
padding: 35px 32px;
width: 160px;
border: solid 1px #fff;
height: 100px;
}

.link_sec .draw-border .text a {
color: #fff;
}

.link_sec .link_sec_bttm {
margin-bottom: 20px;
}


.headline {
text-align: center;
margin-top: 190px;
}

.headline h1 {
color: rgb(255 255 255 / 65%);
font-size: 50px;
}

.headline h3 {
color: rgb(255 255 255 / 65%);
font-size: 20px;
font-weight: normal;
line-height: 42px;
}

.slide-container .slide {
margin: auto;
width: 100vw;
overflow: hidden;
}

div#slide-1 {
background: transparent;
}

div#slide-2, div#slide-3, div#slide-4, div#slide-5, div#slide-6 {
background: #fff
}

.side-img-blk:before {
content: "";
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
background: #212121de;
z-index: 9;
opacity: 0;
transition: 0.8s;
}



.bullets {
position: fixed;
top: 50%;
right: 0;
transform: translate(-50%, -50%);
z-index: 99;
display: none;
}

.bullet {
width: 20px;
height: 20px;
background: #d41259;
border-radius: 50%;
border: 11px solid #d41259;
margin-bottom: 11px;
}

a.active .bullet {
border: 3px solid #ffffff;
position: relative;
}

.fixed-cont.sticky {
height: 90px;
}

.fixed-cont.sticky .logo {
width: 284px;
margin-top: 29px;
}

.fixed-cont.sticky div#menu-bar-icon {
line-height: 18px;
}

.fixed-cont.sticky img {
margin-top: 5px !important;
}

.fixed-cont.sticky .online-registration {
padding: 10px 16px;
}

.message_inner .messagebox {
box-shadow: 0px 14px 18px 19px #d4d4d447;
width: 80%;
margin: auto;
padding: 40px;
margin-top: 80px;
}

.message_inner .messagebox p {
margin: 0px;
}

.message_inner .messagebox img {
    border-radius: 100%;
    width: 200px !important;
    height: 200px;
    margin: auto;
    border: solid 8px #e5e9ff;
    margin-top: -120px;
    display: block;
    text-align: center;
    float: none;
}

.message_inner .messagebox h3 {
margin: 0px;
}

.message_inner .messagebox h4 {
text-align: center;
line-height: 40px;
font-size: 16px;
font-weight: bold;
}

.message_inner .messagebox h5 {
text-align: center;
padding: 0px;
margin: 0px;
}

.event_news_inner {
margin: 0px;
}

.event_news_inner .data_sec ul {
text-align: left;
margin: 0px;
padding: 0px;
display: block;
clear: both;
width: 100%;
}

.event_news_inner .data_sec ul li {
list-style-type: none;
display: inline-block;
margin-right: 17px;
font-size: 14px;
margin-left: 0px;
background: no-repeat;
padding: 0px;
line-height: 25px;
}

.event_news_inner .data_sec ul li .fa {
color: #000;
}

.event_news_inner {
text-align: end;
margin-top: 20px;
position: relative;
}

.event_news_inner select {
width: 120px;
border: solid 1px #ffffff;
color: #ffffff;
padding: 10px 6px;
background: #e5097f;
font-size: 15px;
box-shadow: 2px 2px 2px #bbbbbb;
margin-bottom: 20px;
}

.event_news_inner .data_sec {
margin-bottom: 20px;
background: #fff1f8;
padding: 10px;
margin-top: 10px;
background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/news_event_bg.png');
}

.event_news_inner .data_sec h3 {
    margin-bottom: 5px;
    font-size: 18px;
    text-align: left;
    margin-top: 5px;
    color: #000;
    overflow: hidden;
    line-height: 25px;
}

.event_news_inner .data_sec p {
    transition: all 0.4s ease-in;
    padding-right: 10px;
    font-size: 14px;
    line-height: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 98%;
    text-align: left;
    word-break: break-word;
}

.event_news_inner .data_sec p::-webkit-scrollbar {
width: 5px;
}

.event_news_inner .data_sec p::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
}

.event_news_inner .data_sec p::-webkit-scrollbar-thumb {
background: #eee;
border-radius: 10px;
}

.event_news_inner .data_sec p::-webkit-scrollbar-thumb:hover {
background: #ddd;
}

.event_news_inner .data_sec img {
    background: #f1f1f1;
    border: 1px solid #e6e6e6;
    height: 104px;
    object-fit: cover;
    width: 100%;
    object-position: top;
}
  
 
.event_news_inner .data_sec .date_hidn {
overflow: hidden;
position: relative;
width: 158px;
float: left;
margin-right: 25px;
}

.event_news_inner .data_sec .data_scrol { 
overflow-y: hidden;
margin-bottom: 6px;
}

.event_news_inner .data_sec .read_class {
padding: 3px 10px;
font-size: 12px;
float: left;
text-align: center;
position: relative;
z-index: 99999;
left: 0px;
margin: auto;
right: 0px;
display: block;
margin-top: 15px
}

.event_news_inner .data_sec .attach_sec {
position: absolute;
background: #e5097f;
z-index: 99999;
top: 0px;
padding: 7px;
}

.event_news_inner .data_sec .attach_sec a {
margin: 0px;
color: #fff;
}

.gallery_sec_inner select {
margin-bottom: 20px;
width: 120px;
border: solid 1px #ffffff;
color: #ffffff;
padding: 10px 6px;
background: #2796ed;
font-size: 15px;
box-shadow: 2px 2px 2px #bbbbbb;
float: right;
}

.gallery_sec_inner .photogal p {
    transition: all 0.4s ease-in;
    padding-right: 10px;
    font-size: 15px;
    line-height: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 0px;
    margin-bottom: 10px;
    min-height: 110px;
}

.gal_view_more {
border: solid 1px #fff;
position: absolute;
z-index: 9999;
background: #d41259;
top: 110px;
right: 18px;
padding: 10px;
color: #fff;
height: 35px;
width: 35px;
padding-top: 6px;
}

.gal_view_more:hover {
border-radius: 100%;
}

.gal_view_more .fas {
color: #fff;
}

.gal_view_more .fas:hover {
color: #ecec4c;
}

.data_sec {
position: relative !important;
}

.overlay_news {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: 100%;
width: 100%;
opacity: 0;
transition: .5s ease;
background-color: rgb(255 241 248);
z-index: 99999;
padding: 20px;
color: #fff;
padding-left: 10px;
}

.data_sec:hover .overlay_news {
opacity: 1;
}

.overlay_news p {
color: #000; 
display: block !important;
overflow-y: scroll !important;
}

.read_class {
border: solid 1px #6f0802;
padding: 10px 20px;
color: #000;
text-transform: uppercase;
}

.read_class .fas {
color: #fa1204;
margin-left: 10px;
}

.read_class:hover {
background: #e5097f;
color: #fff;
border: solid 1px #fff;
}

.read_class:hover .fas {
color: #fff;
}

.tooltip {
position: relative;
display: inline-block;
border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
visibility: hidden;
width: 270px;
background-color: #555;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
z-index: 1;
bottom: 125%;
left: 50%;
margin-left: -60px;
opacity: 0;
transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
visibility: visible;
opacity: 1;
}

.gallery_sec_inner .photogal {
background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/gallery_date.png') #d0d0d0;
background-position: left;
padding-bottom: 20px;
margin-bottom: 20px;
padding: 15px;
position: relative;
}

.gallery_sec_inner img {
object-fit: cover;
object-position: top;
}
 
.gallery_sec_inner img {
    border: 2px solid #fff;
    box-shadow: 1px 1px 3px 0 #ddd!important;
    height: 190px;
    margin-bottom: 20px;
    object-fit: contain;
    object-position: center;
    /* padding: 9px; */
    position: relative;
    width: 100%;
    background: #ddd;
}

.gallery_sec_inner h4 {
line-height: 30px;
color: #000000;
text-align: center;
border: solid 1px #f1fff6;
border-radius: 50px;
background-position: right;
width: 93px;
font-weight: bold;
margin: auto;
font-size: 13px;
background: white
}

.gallery_sec_inner .photogal h3 {
    line-height: 26px;
    font-size: 19px;
    color: #d82063;
    font-weight: normal;
    margin-top: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0px;
    text-align: center;
    margin-bottom: 6px;
    min-height: 50px;
}

.gallery_sec_inner h4 a {
color: #ea246d;
}

.gallery_sec_inner h4 a:hover {
color: #000;
}

.gallery_sec_inner h4 span {
color: #ea246d;
font-size: 12px;
}

.gallery_sec_inner h4 .fa {
color: #df1a1c;
}

.gallery_sec_inner .rcl4 {
margin-bottom: 20px;
}

.gallery_sec_inner p {
text-align: center;
}

.gallery_sec_inner h5 {
background: url('https://webapi.entab.info/api/image/LVISGN/public/Images/gal_date.png') no-repeat;
padding: 0px;
position: absolute;
top: 0px;
z-index: 99999;
margin: 0px;
width: 57px;
height: 65px;
padding-top: 2px;
color: #000;
font-weight: bold;
display: none;
}

.gallery_sec_inner_cate img {
margin-bottom: 20px;
box-shadow: 1px 1px 3px 0px #ddd !important;
padding: 9px;
background: #fff;
border: solid 10px #f7f7f7;
width: 100%;
height: 250px;
border-radius: 20px;
cursor: pointer;
box-shadow: 0px 0px 19px 0px #00000054;
border: 7px solid #fff; 
margin:0px 0px 30px 10px;
object-fit: contain;
background: #e5e5e5
}  

.gallery_sec_inner_cate h2 {
letter-spacing: 1px;
text-transform: capitalize;
}

.gallery_sec_inner_cate h4 {
    line-height: 45px;
    font-size: 16px;
color: #020e2b;
text-align: center;
border: solid 1px #c7c7c7;
border-radius: 50px;
font-weight: normal;
margin-bottom: 20px;
}

.gallery_sec_inner_cate h4 a {
color: #e5097f;
font-weight: bold;
}

.gallery_sec_inner_cate h4 a:hover {
color: #0e6d3f;
}

.gallery_sec_inner_cate h4 span {
color: #e5097f;
}

.gallery_sec_inner_cate h4 .fa {
color: #000000;
}

.gallery_sec_inner_cate .rcl4 {
margin-bottom: 20px;
}

.gallery_sec_inner_cate p {
text-align: center;
}

.assistance {
background: rgba(68, 23, 20, 0.06);
padding: 13px;
border: solid 1px #ddd;
}

#myImg, #myImg1, #myImg2 {
width: 100%;
border: 5px solid rgba(158, 158, 158, 0.1);
background: #eaeaea;
cursor: pointer;
transition: 0.3s;
}

#myImg:hover {
opacity: 0.7;
}

.erp_sec h4 {
text-align: center;
line-height: 40px;
font-size: 17px;
margin-bottom: 0px;
margin-top: 15px;
font-weight: bold;
}

.erp_sec p {
line-height: 40px !important;
margin-bottom: 0px;
}

.erp_sec .guideheading {
text-align: left;
}

.erp_sec a {
color: #000;
}

.erp_sec img {
width: 200px;
display: inline-block;
}

.erp_sec .google_img {
width: 60px;
top: 4px;
}

.erp_sec .mozilla_img {
width: 60px;
top: 6px;
}

.erp_sec .internet_img {
width: 20px;
top: 5px;
}

.erp_sec .download_img {
width: 100px;
top: 5px;
}

.help_file {
margin: 0px;
}

.help_file li {
border: solid 1px #eee;
color: #000;
padding: 2px 10px;
background: #fff;
}

.help_file li a {
color: #000;
font-size: 14px;
}

.help_file li a:hover {
margin: 0px;
}

.help_file li:hover {
background: #fff0f5;
}

.online_reg {
position: absolute;
z-index: 9;
background: #e5097f;
padding: 10px;
bottom: 0px;
left: 0px;
margin: auto;
right: 0px;
max-width: 400px;
text-align: center;
animation: blinker .5s linear infinite;
border: solid 1px #fff;
}

@keyframes blinker {
50% {
    background: #770900;
}
}

.online_reg ul {
margin: 0px;
}

.online_reg ul li {
display: inline-block;
}

.online_reg ul li a {
margin: 0px;
}

.online_reg ul li .fa {
color: #fff;
margin-right: 20px;
}

.online_reg ul li .fa:hover {
margin: 0px;
}

.online_reg ul li h3 {
margin: 0px;
}

.online_reg ul li h3 a {
margin: 0px;
color: #fff;
font-size: 19px;
line-height: 35px;
}

.online_reg ul li h3 a:hover {
margin: 0px;
}

.online_reg ul li img {
width: 35px;
position: absolute;
left: 10px;
top: 5px;
}
    

.sitemap_sec {
background: #1c619e;
padding: 3px 10px;
color: #fff !important;
}

.sitemap_sec:hover {
background: #000000;
}

.online_fee {
position: fixed;
z-index: 99;
right: 0px;
bottom: 200px;
}

.online_fee img {
width: 160px;
border-radius: 30px;
}

.sitemap_sec {
background: #3e6b4d;
padding: 3px 10px;
color: #fff !important;
}

.sitemap_sec:hover {
background: #bb0645;
}
.chooseyear { margin-bottom: 40px;}
#chooseddlYear {
  border: unset;
  color: #000;
  height: 30px;
  padding: 0px 0px 0px 4px;
  background: unset;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
  width: 6.5em;
  font-size: 15px;
  float: right;
  margin-top: 0;
  border-bottom: 1px solid grey;
}
.chooseyear select {
  margin: 0px;
}
select#chooseddlYear:focus-visible {
  outline: none;
}
.btn-danger {
    width: auto;
    float: right;
    padding: 9px 20px;
    background: #4f795c;
    color: #fff!important;
    margin-right: 10px;
    cursor: pointer;
    font-size: 15px;
    text-decoration: none;
    margin-top: 1px;
}
.form_sec input::placeholder{
    font-size: 12px;
}

  ul.react-tabs__tab-list {
    display: flex;
    padding: 0px;
    margin-bottom: 0;
    flex-wrap: wrap;
}

.react-tabs__tab {
    list-style: none;
    outline: none;
        border: solid 1px #d8d8d8;
    width: max-content;
    padding: 5px 15px;
    margin-right: 25px;
    margin-bottom: 25px !important;
}

ul.react-tabs__tab-list li {
    list-style: none;
    cursor: pointer;
}
.react-tabs__tab--selected {
 border-bottom: 2px solid #f90067;
}
.testimonialsvideobox h4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.accordion-item {
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  
  .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background-color: #fde7f0;
  }
  
  .accordion-content {
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
  }
  .accordion-title h4 {
    font-size: 17px;
    margin-bottom: 0;
}
.trainers .accordion-button {
    font-family: 'Poppins';
    background-color: #fde7f0;
    margin: 0px;
    padding: 5px 5px;
    font-size: 17px !important;
    font-weight: normal;
    margin-bottom: 10px;
    border: solid 1px #ffd4e4;
    color: #01191d;
}
.trainers h2.accordion-header {
    margin-top: 0px !important;
    line-height: 25px;
}
.trainers .accordion-item {
    border: 0px solid #ccc;
}
button.accordion-button:focus {
    box-shadow: none;
}
.popup-gallery img {
    margin-bottom: 20px;
}

.magnify-modal {
    position: fixed !important;
    z-index: 999999999 !important;
    padding-top: 10px !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
    float: right;
    position: relative;
    z-index: 9
}

.magnify-stage {
    position: absolute;
    top: 40px;
    right: 10px;
    bottom: 40px;
    left: 10px;
    z-index: 1;
    background: #0000000a;
    overflow: hidden
}

.magnify-image {
    position: relative;
    display: inline-block;
    border: 5px solid #fff
}

.magnify-image.image-ready {
    max-width: 100%;
    max-height: 100%
}

.magnify-footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center
}

.magnify-footer .magnify-toolbar {
    display: inline-block
}
 
.magnify-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-width: 0;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    background: #055a91;
}

.magnify-button:hover {
    color: #fff;
    background-color: #e00000
}

.magnify-button-close:hover {
    background-color: #e00000
}

.magnify-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #333
}

.magnify-loader::before {
    content: '';
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    -webkit-animation: magnifyLoading 1s infinite linear;
    animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    overflow: hidden
}

@-webkit-keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
}

@keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
}


/* notification */


div.nextPage, div.prevPage {
    padding: 5px;
    background: #fff;
}

#exampleModalToggle .carousel-item:before{
    content: '';background: #00000021;width: 100%;height: 100%;position: static;
}


#exampleModalToggle .carousel-control-prev-icon {
    filter: invert(1);
}

#exampleModalToggle .carousel-control-next-icon {
    filter: invert(1);
}

#exampleModalToggle .carousel-control-next,
#exampleModalToggle .carousel-control-prev {
    width: auto;
}

#exampleModalToggle .carousel-control-next {
    right: 0px;
}

#exampleModalToggle .modal-dialog {
    min-width: 800px;
}

.carousel-item .modal-header {
    background: linear-gradient( to right, #cd1616, #fcb045);
    padding: 10px;
}

#exampleModalToggle .modal-dialog img {
    width: 100%;
    object-fit: contain;
    background: #f1f1f1;
}

.carousel-item .modal-header h1 {
    padding-bottom: 0;
    text-align: center;
    font-size: 17px;
    color: #fff;
    overflow: hidden;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    line-height: 28px;
    font-weight: normal;
    margin: 0 auto;
    width: 100%;
}

.carousel-item .modal-dialog .modal-header .btn-close {
    color: #fff;
    padding-right: 31px
}

.carousel-item .modalcard .card-body {
    padding: 10px 10px 0px 10px;
}

.carousel-item .modalcard .card-body p {
    overflow: hidden;
    text-align: center;
    padding-top: 10px;
    line-height: 34px;
    font-weight: 100;
    font-size: 16px;
}

.carousel-item .modalcard {
    border: none;
    margin-bottom: 0;
}

.carousel-item .modal-body {
    padding: 0px 1rem;
}

#exampleModalToggle .modal-content {
    padding: 0 0 0px;
}
 
.carousel-item button.btn-close:focus {
    box-shadow: none
}

.carousel-item a.btn.btn-primary.btn-sm {
    background: #d9281f;
    border: #d9281f;
    margin: 0 10px;
    color: #fff;
}

.carousel-item a.btn.btn-primary.btn-sm:focus {
    box-shadow: none;
    border: 1px solid #182b3c;
}

.modal { background: #0000008a; z-index: 99999; }

.carousel-item .btn-close {
    opacity: 1;
    position: relative;
    z-index: 2;
    }

    div#exampleModalToggle  .d-grid.gap-2.d-md-flex.justify-content-around.d-block.mx {
        width: 100%;
        display: flex !important;
        margin-bottom: 20px;
    }

    .month-tabs ul.nav.nav-tabs li {
        margin-bottom: 20px;
        list-style: none;
    }
    .month-tabs ul.nav.nav-tabs li button{
        color: #000;
    }
    .inner_page ul li {
        background: #ddd;
        margin: 5px 10px;
        border-radius: 5px;
        color: #000;
    }
    
    .inner_page ul li button {
        color: #000;
    }
    
    .month-tabs ul.nav.nav-tabs li.active {
        background: #d41259;
        color: #fff;
    }
    
    .month-tabs ul.nav.nav-tabs li.active button {
        color: #fff;
    } 
    .filter-section select {
        border: 0px solid #000;
        border-bottom: 1px solid #000;
        border-radius: 0;
        padding: 6px 0;
        width: max-content;
        margin-left: auto;
        text-transform: uppercase;
        margin-left: auto;
        display: block;
        margin-bottom: 20px;
        padding-right: 30px;
        margin-right: 10px;
    }
    .media-img a img {
        width: 100%;
        object-fit: contain;
        background: #ededed;
    }
    .month-tabs ul{border-bottom: 0;}
    .acheivement_2024 img {
        width: 100%;
        height: 380px;
        object-fit: contain;
        background: #ddd;
        box-shadow: 0px 0px;
        border: 5px solid #fff;
        margin-bottom: 20px;
        box-shadow: 0px 0px 5px 0px #ddd;
    }
    .row.acheivement_2024 ul{padding: 0px 10px;}
    .row.acheivement_2024 ul li {
        background: transparent;
    }
    .row.acheivement_2024 {
        border: 1px solid #ddd;
        padding: 20px;
        width: 98%;
        margin: auto;
        margin-top: 20px;
    }
    
    .acheivement_2024 p {
        margin-bottom: 7px;
    }
    .achievementbox.achievementbox-static {
        overflow: visible;
    }
    
    .achievementbox.achievementbox-static img.img-fluid {
        width: 100%;
        height: auto;
    }
    .no-scroll{
        height: auto;
        max-height: unset !important;
        min-height: auto;
        overflow: unset !important;
    } 

.achievementbox.no-scroll .main {
    top: 0;
}

.achievementbox.no-scroll .main p {
    margin-top: 0;
    margin-bottom: 25px;
}

.achievementbox.no-scroll img {
    border: 7px solid #fff;
    box-shadow: 0px 0px 16px -9px #000;
    object-fit: contain;
    width: 98%;
} 
.training_img img {
    height: 250px;
    width: 100%;
    object-fit: contain;
    border: 6px solid #fff;
    box-shadow: 0px 0px 5px 0px #ddd;
    margin-bottom: 25px;
    background: #fde7f0;
}
.topper-service {
    background: #fff;
    box-shadow: 0px 0px 20px -6px #4e4e4e8c;
    border: 9px solid #fff;
    text-align: center;
}

.topper-service img {
    height: 220px;
    object-fit: contain;
    width: 100%;
    background: #ddd;
}

.topper-service p {
    text-align: center;
    margin-bottom: 0;
    text-transform: uppercase;
}

.topper-service h3 {margin-bottom: 0;}
.img_data_sec .box p {
    text-align: center;
    font-size: 14px;
    color: green;
    font-weight: 400;
    padding-bottom: 10px;
}

.img_data_sec .box h4 {
    margin-bottom: 0;
    line-height: 38px;
}
.img_data_sec .box p i {
    margin-right: 5px;
}
@media (max-width: 991px) {
    .header__toggle{
        z-index: 99;
        position: relative;
    }

    #exampleModalToggle .modal-dialog { 
        max-width: 95%;
        min-width: 95%;
    }
.pop-up-block h1 {
    color: #000;
}

.pop-up-block p {
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 60px;
    margin-top: 0px;
}



}

@media (max-width: 767px) {
    .react-tabs__tab{
        margin: 5px 7px;
        margin-bottom: 10px !important;
    }
    .inner_page .bgimage{
        height: auto;
    }
    .container-fluid.flwidth {
        width: calc(96% - 20px);
        background: #fff;
    }
    .acheivement_2024 img, .img_data_sec img{
        height: auto;
    }
.pop-up-block h1 {
    font-size:17px;
    line-height: 25px;
    height: 26px;
}.pop-up-block p{font-size:13px;}


}

@media screen and (max-width: 1479px) {
    .link_sec .draw-border h3{
        font-size: 17px;
    }
    .link_sec .draw-border p {
        /* -webkit-box-orient: vertical; */
        color: #fff;
        /* display: -webkit-box; */
        font-size: 14px;
        /* height: 126px; */
        line-height: 22px;
        overflow: hidden;
    }
.menu_new a {
    padding: 8px 9px;
    font-size: 18px !important;
}

.submenu a {
    font-size: 15px !important;
    padding: 13px 0px !important;
}

.menu .menuitem a {
    white-space: pre-wrap;
    display: block;
}

.submenu a {
    line-height: 20px;
}

.menu_btn {
    top: 10px;
}
}

@media screen and (max-width: 1199px) {
    .link_sec .draw-border h3 { 
        margin-bottom: 9px;
        font-size: 15px; 
    }
    /* .gallery_sec_inner_cate img {
        width: calc(34% - 20px);
    } */
.footer_sec .container {
    width:100%;
}

.gallery_sec_inner h4 {
    margin-bottom: 20px;
}

.mobile_app {
    margin-left: 0%;
}

.app_title {
    margin-left: 0%;
}

.office_time_sec p {
    line-height: 30px;
}

.ftr_logo {
    display: none;
}

section {
    height: auto;
}

.office_time_sec  {
    height: auto;
}
}

@media screen and (max-height: 700px) {
section {
    height:auto;
}

.office_time_sec p {
    line-height: 30px;
}

.menu_new a {
    margin-bottom: 0px;
    margin-top: 3px;
    padding: 9px 9px;
    font-size: 15px !important;
}

.submenu a {
    margin: 0px;
    padding: 12px 0px !important;
}
}

@media screen and (min-width: 991px) {
    
.header {
    height: calc(var(--header-height) + 1rem);
}

.header__logo, .header__toggle {
    display: none;
}

.nav {
    width: 100%;
}

.nav__content {
    justify-content: space-between;
    align-items: center;
}

.nav__perfil {
    flex-direction: row;
    text-align: initial;
    margin-bottom: 0;
}

.nav__img {
    width: 40px;
    height: 40px;
    margin-right: .5rem;
    margin-bottom: 0;
}

.nav__img img {
    width: 46px;
}

.nav__name {
    color: var(--dark-color);
}

.nav__list {
    /* display: flex; */
    /* align-items: center; */
}

.nav__item {
}

.nav__link {
    width: 100%;
    padding: 7px 7px;
    line-height: 23px;
    display: flex;
}

.nav__link:hover {
    color: var(--first-color);
}

/*Active link new color*/
.active {
    color: var(--first-color);
}

.dropdown {
    position: relative;
}

.dropdown__menu {
    position: fixed;
    margin: 0;
    top: calc(var(--header-height) + 1rem);
}
}

@media (max-width: 1199px) {
.nav__link {
    font-size: 13px;
    padding: 0px 2px;
    line-height: 30px;
}

.nav__item {
    padding: 0px 2px !Important;
}

.logo_sec .container {
    width: 100%;
}
}

@media screen and (min-width: 1024px) {
.bd-grid {
    margin-left: auto;
    margin-right: auto;
}
}

@media screen and (max-width: 991px) {
    .nav__content{
        padding: 10px 0 !important;
        background: #272727;
    }
  .menu_sec  .nav--open {
        display: block;
        left: 0;
        width: 100%; 
        top: 21px;
        height: 100%;
    } 
    .menu_sec ul{
        padding: 0
    }
    .header .nav__menu{
        padding: 0;
    }
    .menu_new > div >.menuitem:hover > .submenu {
        position: static;
        opacity: 1;
        transform: translateY(0%);
        transition: transform 0.6s, opacity 0.6s;
        background: #424242eb;
        padding: 0px 17px;
    }
    .gallery_sec_inner_cate img {
        width: calc(50% - 20px);
    }
.footer_sec h3 {
    margin-bottom: 10px;
    margin-top: 40px;
}

.footer_sec {
    padding-top: 20px;
}

.testimonial_sec .testi_1 .data_sec {
    width: 330px;
    margin: auto;
}

.overlay.open {
    padding: 20px;
}

.headline h1 {
    font-size: 30px;
}

.logo_sec img {
    
    width: 320px;
}

.nav__link {
    color: #fff;
}

.office_time_sec img {
    margin-top: 20px;
}

.office_time_sec  {
    background: #538e65;
    padding-top: 20px;
}

.menu_sec {
    height: 39px;
    padding-left: 15px;
    padding-top: 7px;
}

.clr991 {
    clear: both;
}

.menu_sec ul {
    text-align: left;
}

.menu_sec ul li {
    display: block;
    line-height: 30px;
}

.nav__content {
    padding: 20px;
    margin-top: 146px !important;
}
.dropdown__link{
color: #fff !important;    
}
.menu_new >div{
    width: 98%;
}
.nav {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: var(--first-color);
    width: 80%;
    /* height: 100vh; */
    padding: 2rem 0;
    z-index: 9999;
    transition: .5s;
    overflow-y: auto;
}
}

@media screen and (max-width: 767px) {
    .link_sec .draw-border{
        height: auto;
    }
.link_sec .draw-border p {
    text-align:left;
}

.footer_sec_btm p {
    line-height: 23px;
}

.event_sec .draw-border {
    padding: 5px;
}

.event_sec .draw-border h3 {
    height: 80px;
    font-size: 14px;
    -webkit-line-clamp: 3;
}

.about_Sec .aboutbox {
    margin-bottom: 20px;
}

.about_Sec .aboutbox p {
    text-align: left;
    -webkit-box-orient: inherit;
    overflow: inherit;
    height: auto;
}

.overlay_sec {
    position: initial;
    background: none;
}

.about_Sec .aboutbox img {
    width: 30px;
}

.about_Sec .aboutbox h3 {
    font-size: 16px;
}

.achivemnt_sec .achievementbox {
    max-height: inherit;
}

.button_container {
    width: 66%;
    margin: auto;
    display: block;
    position: relative;
}

.menu_btn {
    width: 60px;
    padding: 0px;
    text-align: center;
    padding-top: 13px;
    border-radius: 7px;
}

.menu_btn span {
    left: -1px; 
    width: 100%;
}

.menu_tx {
   
     display:none;
     
    text-align: center;
    font-size: 13px;
}

.pic-wrapper {
    height: 48vh;
}

.online_reg ul li h3 {
    font-size: 15px;
}

.inner_page .bgimage {
    margin-bottom: 20px;
}

.bullets {
    display: none;
}

.testimonial_sec .testi_1 .data_sec {
    width: 300px;
}

.submenu a {
    font-size: 15px !important;
    padding: 9px 0px !important;
}

.menu_new > header {
    width: 200px;
}

.menu_new a {
    padding: 5px 5px;
    font-size: 15px !important;
}

.clr_change1 {
    display: none;
}

.inner_img, .inner_img_right {
    float: none;
    margin: auto;
    margin-bottom: 20px;
}

.inner_page {
    min-height: 450px;
}

.nav__content {
    margin-top: 126px !important;
}

.logo_sec li .fas {
    margin-right: 5px;
}

.logo_sec ul li {
    display: inline-block;
}

.logo_sec ul {
    margin: 20px 0px;
    text-align: center;
    float: none;
}

.logo_sec li span {
    display: inherit;
}

.footer_sec h3 {
    margin-top: 50px;
}

.footer_sec {
    padding-top: 0px;
}

.event_sec .draw-border {
    margin-bottom: 20px;
}

.logo_sec img {
    margin: auto; 
    position: relative;
    z-index: 9;
}

.container {
    width: 100%;
}

.header .nav__menu {
    padding: 0px 20px;
}

.nav {
    position: absolute;
    top: 0;
    left: -100%;
    background-color: var(--first-color);
    width: 80%;
    /* height: 100vh; */
    padding: 2rem 0;
    z-index: var(--z-fixed);
    transition: .5s;
    overflow-y: auto;
    z-index: 9;
}
}

.nav__content {
margin-top: 0px;
margin-bottom: 0px;
}

.nav__perfil {
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
margin-bottom: 3rem;
}

.nav__img {
display: flex;
justify-content: center;
width: 60px;
height: 60px;
border-radius: 50%;
overflow: hidden;
margin-bottom: 1rem;
}

.nav__img img {
width: 70px;
}

.nav__name {
display: block;
font-size: var(--nav-name-font-size);
color: var(--white-color);
} 

.nav__link:hover {
color: #ffe000;
}

/*Show menu*/
.show {
left: 0;
}

/*Active link*/
.active {
color: var(--white-color);
}

.dropdown__link {
align-items: center;
justify-content: space-between;
}

.dropdown__icon {
font-size: 15px;
transition: .5s;
margin-left: 3px;
/* display: none; */
}

.dropdown__menu {
margin: 0px 15px;
display: none;
}

.dropdown__item {
line-height: 15px;
line-height: 20px!important;
}

.dropdown:hover > .dropdown__menu {
display: block;
}

.dropdown:hover .dropdown__icon {
transform: rotate(180deg);
}

@media screen and (max-width: 650px) {
.testimonial_sec .testi_1 .data_sec {
}
}

@media screen and (max-width: 600px) {
.gallery_sec .big_img {
    height:200px;
}

.contact_page .fa, .contact_page .school {
    width: 30px;
    height: 30px;
    padding-top: 9px;
    font-size: 12px;
    margin-right: 14px;
    margin-bottom: 20px;
}

.contact_page h3 {
    font-size: 18px;
}

.inner_page {
    padding-top: 20px;
    padding-bottom: 20px;
}

.inner_page h5, .inner_page h5 a {
    font-size: 13px;
}

.inner_page h2 {
    font-size: 20px;
    background: #d41259;
    width: 100%;
    height: auto;
    padding-top: 0px;
    line-height: 65px;
    color: #fff;
}

.board_members_sec .advisory h4 {
    text-align: center;
}

.board_members_sec .advisory img {
    float: none;
    margin: auto;
    margin-bottom: 20px;
}

.fullwidth600 {
    width: 100%;
}
}

@media screen and (min-width: 576px) {
.nav {
    width: 288px;
    color: #fff;
}
}

@media screen and (min-width: 991px) {
.header {
    height: calc(var(--header-height) + 1rem);
}

.header__logo, .header__toggle {
    display: none;
}

.nav {
    width: 100%;
}

.nav__content {
}

.nav__perfil {
    flex-direction: row;
    text-align: initial;
    margin-bottom: 0;
}

.nav__img {
    width: 40px;
    height: 40px;
    margin-right: .5rem;
    margin-bottom: 0;
}

.nav__img img {
    width: 46px;
}

.nav__name {
    color: var(--dark-color);
} 

.nav__item { 
    text-transform: uppercase;
}

.nav__link:hover {
    color: #ffffff;
    background: #e53777;
}

.active {
    color: #fff;
}

.dropdown {
    position: relative;
}

.dropdown__menu {
    position: absolute;
    margin-top: 0px;
    top: 34px;
    padding: 0 9px;
    background: #101012;
    z-index: 9999;
    text-align: left !important;
    width: 285px;
    left: -15px;
    padding-bottom: 5px;
}
}

@media (max-width: 550px) {
   
    .gallery_sec_inner_cate img {
        width: 93%;
    }
.news_sec img {
    display:none;
} 
 
.button_container {
    width: 51%;  
   
}.menu_btn span:nth-of-type(3) {
    top: 15px;
}
.menu_btn span:nth-of-type(2) {
    top: 6px;
}
.menu_btn { height: 43px;
    width: 51px;}
.menu_btn span{
    height: 2px;
}

.overlay.open {
    padding: 10px;
    overflow: scroll;
    padding-top: 50px;
}.menu_btn.active .top {
    transform: translateY(6px) translateX(0) rotate(43deg);
    background: #000;
}

.motto_sec h2 {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
}

.motto_sec {
    margin-top: 20px;
}

.fullwidth550 {
    width: 100%;
}

.mob_view {
    display: block;
}

.normal_view {
    display: none;
}

.highligh_sec ul li .fab {
    width: 10px;
    font-size: 11px;
    text-align: center;
}

.event_importnt_sec h2 {
    font-size: 23px;
} 
}
@media (max-width: 550px){
.imgdiv {
    display: flex;
    flex-wrap: wrap;
}
}
@media (max-width: 500px) {
.online_fee img {
    width:100px;
}
 
 

.nav__content {
    margin-top: 126px !important;
}

.logo_sec img {
    width: 100%;
    margin: auto; 
    margin-top: 0px;
    width: 80%;
    margin-left: 0 !important;
    
}

.menu_new a {
    padding: 5px 5px;
    min-width: inherit;
}

.menu_new > div {
    width: 100%;
}

.event_importnt_sec h2 .readmore {
    display: block;
    margin-top: 10px;
}

.fullwidth500 {
    width: 100%;
}

.erp_links img {
    width: 50px;
}
}

@media (max-width: 450px) {
.office_time_sec p a {
    font-size:13px;
}

.pic-wrapper {
    height: 30vh;
}

menu.submenu {
    padding-left: 2px;
}
.menu_btn.active .top {
    transform: translateY(7px) translateX(0) rotate(43deg);
    background: #000;
}

.mobile_app_link {
    width: 231px;
    margin-left: 5px;
}

.testimonial_sec .testi_1 .data_sec {
    /* width:250px; */
    margin: auto;
}

.fullwidth450 {
    width: 100%;
}
}
 
  